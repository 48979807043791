import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import styles from './styles/RadialChartWidget.module.scss';
import type { RadialChartWrapperProps } from './types/RadialChartWidget';

export interface GraphProps {
  color?: string;
  labelStart?: string;
  labelEnd?: string | boolean;
  shape?: 'circle' | 'rectangular';
  text?: string;
}

const RadialChartWidget: React.FC<RadialChartWrapperProps<GraphProps>> = ({
  data,
  indicators,
  size = '100%',
  text,
  title,
}) => {
  const { t } = useTranslation();
  function setLabelPosition(labelEnd: string | boolean): string {
    if (labelEnd === '0' || labelEnd === '1' || typeof labelEnd === 'boolean') {
      return labelEnd ? '0' : '1';
    }
    return labelEnd;
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <BigUp.Label.V2Thick label={t(title)} color='medium' />
          </IonCol>
        </IonRow>
      </IonGrid>
      <BigUp.Widget.ChartContainer>
        <section className={styles.radialChartSection}>
          <div className={styles.chartWrapper}>
            <BigUp.Graphs.RadialChart
              label={{
                text,
              }}
              data={data}
              percentageIndex={1}
              size={size || '100%'}
            />
          </div>
          <div className={styles.indicatorWrapper}>
            {indicators.map((indicator, index) => (
              <div className={styles.indicator} key={index}>
                <BigUp.Label.Indicator
                  indicator={{
                    color: indicator.color,
                    shape: indicator.shape || 'circle',
                  }}
                  label={{
                    start: {
                      label: t(indicator.labelStart)
                    },
                    end: {
                      label: setLabelPosition(indicator.labelEnd),
                    },
                  }}
                />
              </div>
            ))}
          </div>
        </section>
      </BigUp.Widget.ChartContainer>
    </>
  );
};

export default RadialChartWidget;
