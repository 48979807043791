import { IonLabel } from '@ionic/react';
import React from 'react';

import type { IndicatorProps, LabelProps } from './interface';
import styles from './Label.module.scss';

const Regular: React.FC<LabelProps> = ({ children, label, ...props }) => {
  return (
    <IonLabel {...props} style={{ fontSize: props.style?.fontSize, color: props.color ?? undefined }}>
      {label}
      {children}
    </IonLabel>
  );
};

const Thick: React.FC<LabelProps> = ({ children, label, ...props }) => {
  return (
    <IonLabel {...props}
      className={`${styles.thickLabel} ${props.className || 'ion-no-margin'}`}
      style={{ color: props.color || 'medium' }}
    >
      {label}
      {children}
    </IonLabel>
  );
};
const V2Thick: React.FC<LabelProps> = ({ label, ...props }) => {
  return (
    <IonLabel {...props} className={`${styles['thick-label-v2']} ion-no-margin`}>
      {label}
    </IonLabel>
  );
};

const Indicator: React.FC<IndicatorProps> = ({ indicator, label }) => {
  const { color, shape } = indicator;
  const { end, gap, start } = label;

  return (
    <div className={styles.indicator}>
      <div className={styles.indicator__container}>
        <div
          className={`${styles.indicator__shape} ${styles[`indicator__shape--${shape}`]}`}
          style={{ background: color }}
        />
      </div>
      <div className={styles.indicator__label} style={{ gap }}>
        <Regular {...start} className={styles['indicator__label-start']} />
        <Regular {...end} className={styles['indicator__label-end']} />
      </div>
    </div>
  );
};

const Label = {
  Regular,
  Thick,
  V2Thick,
  Indicator,
};

export default Label;
