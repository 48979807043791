import styles from '../styles/ChartContainer.module.scss';

interface ChartContainerProps {
  children: React.ReactNode;
  dimensions?: {
    width?: number;
    height?: number;
  };
  padding?: {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
  }
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  children,
  dimensions = { height: 200 },
  padding
}) => {
  return (
    <div style={{
      height: dimensions.height,
      maxWidth: dimensions.width,
      paddingLeft: padding?.left,
      paddingTop: padding?.top,
      paddingRight: padding?.right,
      paddingBottom: padding?.bottom
    }}
    className={styles.chartContainer}>
      {children}
    </div>
  );
};

export default ChartContainer;
