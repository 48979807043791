import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useParams } from 'react-router';

import WorkUpValueLineChart from './Graphs/WorkUpValueLineChart';
import styles from './PaymentSchedule.module.scss';
import PaymentScheduleRows from './PaymentScheduleRows';
import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import SwiperController from '../../../../components/Swiper/SwiperController';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import { emptyListMessages } from '../../../../constants/messages';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import { setPaymentSchedule, setWorkupGraphData } from '../../../../reducers/payment-schedule';

const PaymentSchedule: React.FC = () => {
  const store = useStore();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [swiperController, setSwiperController] = useState<SwiperController>(
    new SwiperController()
  );

  const paymentSchedule: E2U.V1.Models.PaymentSchedule | undefined = useAppSelector((state) => state.paymentSchedule.paymentSchedule);
  const isLoadingPaymentSchedule: boolean = useAppSelector((state) => state.loading.isLoading.paymentSchedule);

  const fetchWorkupGraphData = () => {
    store.dispatch(setIsLoading({ name: 'workupGraphData', value: true }));
    toasters.promise(networking.get(`/api/v1/projects/${uuid}/workup_rate`), {
      error: t('Error while getting workup graph data')
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.LineGraphData>) => {
        store.dispatch(setWorkupGraphData(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(
          setIsLoading({ name: 'workupGraphData', value: false })
        );
      });
  };

  const fetchPaymentSchedule = () => {
    const endpoint = `/api/v1/projects/${uuid}/payment_schedule?with=rows`;
    store.dispatch(setIsLoading({ name: 'paymentSchedule', value: true }));
    toasters.promise(
      networking.get(endpoint), { error: t('Error while getting payment schedule') })
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.PaymentSchedule>>) => {
        if (response.data.data.records.length > 0) {
          store.dispatch(setPaymentSchedule(response.data.data.records[0]));
          fetchWorkupGraphData();
        } else {
          store.dispatch(setPaymentSchedule(undefined));
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        setError(error.message);
        Sentry.captureException(error);
      })
      .finally(() => store.dispatch(setIsLoading({ name: 'paymentSchedule', value: false }))
      );
  };

  useEffect(() => {
    fetchPaymentSchedule();
    setSwiperController(swiperController);
  }, [uuid]);

  const LoadingSkeleton = () => (
    <React.Fragment>
      <SkeletonTextThreeLines />
    </React.Fragment>
  );

  const messages = {
    empty: {
      title: emptyListMessages.payment_schedule.empty_title,
      message: emptyListMessages.payment_schedule.empty_message
    },
    error: {
      message: ''
    }
  };

  return (
    <DesktopWrapper>
      <div className={styles['payment-schedule-accordion-container']}>
        <div className="ion-no-padding" slot="content">
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.Title label={t('Payment schedule')} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <WorkUpValueLineChart />
          {isLoadingPaymentSchedule
            ? <LoadingSkeleton />
            : typeof paymentSchedule === 'undefined' || paymentSchedule.rows.length <= 0
              ? handleEmptyListStates({
                isEmpty: typeof paymentSchedule === 'undefined' || paymentSchedule.rows.length <= 0,
                error,
                messages
              })
              : <React.Fragment>
                <IonGrid className='ion-padding ion-margin-horizontal'>
                  <IonRow>
                    <IonCol size='12'>
                      <PaymentScheduleRows
                        paymentSchedule={paymentSchedule}
                        fetchPaymentSchedule={fetchPaymentSchedule}
                        swiperController={swiperController}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </React.Fragment>
          }
        </div>
      </div>
    </DesktopWrapper>
  );
};
export default PaymentSchedule;
