import format from 'number-format.js';

export default function formatNumber(num: number) {
  if (num === 0) {
    return '0';
  } else if (num < 1000) {
    return format('# ###.', num);
  }
  return format('#,###.', num);
}
